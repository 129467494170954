<template>
<div class="upgrading">
  <div class="signup-body">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class=" col-12 col-md-8 col-lg-7 align-self-center">
          <h1 class="mb-3 signup-header">We are coming back soon!</h1>
          <p class="mb-4">We're currently upgrading our job marketplace for employers. <br>
          We can still help you find freelancers using our <a href="/concierge" class="underline orange-text">Concierge</a> or <a href="https://www.citronworkers.com/" class="underline orange-text">Team Management</a> services.</p>
          <img src="images/comingsoon.png" alt="" class="" style="max-width: 380px; width: 100%;">
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    data() {
      return {
        radio: {},
        unsubscribe: {},
      }
    }
  }
</script>

<style lang="scss">
  .unsubscribe {
    .signup-body .form {
      padding: 30px 40px 20px 30px;
    }
    .el-form .el-radio {
      margin-bottom: 12px;
    }
    .el-radio>.el-radio__label {
      line-height: 1.6;
      word-wrap: break-word;
      white-space: normal;
      display: inline-block;
    }
    .el-radio__input.is-checked .el-radio__inner {
      border-color: #E87722;
      background: #E87722;
    }
    .el-radio__input.is-checked+.el-radio__label {
      color: #E87722
    }
  }
  @media (max-width: 575px) {
    .unsubscribe {
      .el-radio {
        margin-right: 10px;
      }
      .signup-body .form {
        padding: 30px 30px 20px 30px !important;
      }
    }
  }
</style>
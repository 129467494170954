<template>
<div class="signup employer">
  <div class="signup-body">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class=" col-12 col-md-8 col-lg-6 pt-5 pt-lg-0 pr-3 pr-md-5 align-self-center">
          <h3 class="mb-2 signup-header">Unsubscribe</h3>
          <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua.</p>
          <img src="/images/beta.png" style="max-width: 600px; width: 100%;"/>
          
        </div>
        <div class="content mt-3 col-md-8 col-lg-6 p-0">
          <hr>
          <div class="form">
            <el-form ref="form" :model="contact">
              <el-row :gutter="20">
                <el-col :xs="24" :md="12">
                  <el-form-item label="Name*" prop="last_name">
                    <el-input v-model="contact.name"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :md="12">
                  <el-form-item label="Email Address*" prop="email_address">
                    <el-input v-model="contact.email"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :md="24">
                  <el-form-item label="Subject" prop="email_address">
                    <el-input v-model="contact.subject"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :md="24">
                  <el-form-item label="Message">
                    <el-input type="textarea" v-model="contact.message" :rows="5"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24">
                  <el-form-item class="text-center buttons">
                    <el-button type="warning">Submit</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    data() {
      return {
        contact: {},
      }
    }
  }
</script>

<style scoped>
  label, .el-form-item__label{
    margin-bottom: 0;
  }
  .tab {
    padding: 0;
  }
  .toggle-btns ul.tab li label {
    padding: 15px 20px;
    width: 200px;
    font-size: 14px;
    border: 1px solid #DCDFE6;
  }
  @media (max-width: 575px) {
    .toggle-btns ul.tab li label {
      width: 99%;
      max-width: 100%;
      margin: 0 !important;
    }
    .toggle-btns .tab li {
      display: block !important;
      width: 100%;
      margin: 0 !important;
    }
  }
</style>